$(document).ready(function () {
  // request permission to show notifications on page load
  document.addEventListener('DOMContentLoaded', function () {
    if (!Notification) {
      console.error('Desktop notifications are not available.')
      return
    }

    if (Notification.permission !== 'granted') {
      Notification.requestPermission()
    }
  })
})

function sendBrowserNotification (message, title, url) {
  return new Promise(function (resolve, reject) {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission(function (permission) {
        if (permission == 'granted') {
          return sendBrowserNotification(message, title, url)
        }
      })
      return reject('NO_PERMISSION')
    } else {
      if (!title) {
        title = 'PrismaNote Software'
      }

      var msg = message.replace(/<\/?[a-z][a-z0-9]*[^<>]*>/ig, '')
      var notification = new Notification(title, {
        icon: 'https://prismanotevoorjuweliers.nl/wp-content/uploads/2015/12/cropped-prismanote-logo-def-klein-3-1.png',
        body: msg
      })

      if (!url) {
        url = window.location.origin + '/retailer/notifications'
      }

      notification.onclick = function () {
        window.open(url)
      }
    }
  })
}
